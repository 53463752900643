import {BikiniEvent} from '../types/bikini'

import React from 'react'
import {graphql} from 'gatsby'
import {ThemeProvider} from 'styled-components'

import Layout from '../layout'
import Banner from '../components/banner'
import {Title} from '../components/styled'
import Event from '../components/event'

import {eventFilter} from '../helpers/date'

const TagTemplate = ({data, pageContext: {tag}}) => {
  const events: BikiniEvent[] = data.allBikiniEvent.edges.map(({node}) => node).filter(eventFilter)
  const title = `#${tag}`

  return (
    <Layout title={title} noindex={events.length === 0}>
      <Banner />
      <Title>{title}</Title>
      <ThemeProvider theme={{squares: false}}>
        <>
          {events.length ? (
            events.map((event) => <Event key={event.id} event={event} />)
          ) : (
            <p>Ce tag ne contient aucun concert à venir.</p>
          )}
        </>
      </ThemeProvider>
    </Layout>
  )
}

export default TagTemplate

export const query = graphql`
  query($tag: String!) {
    allBikiniEvent(filter: {past: {eq: false}, _tags: {eq: $tag}}, sort: {fields: [date], order: ASC}) {
      edges {
        node {
          id
          path
          date
          end
          unveilDate
          title
          style
          image {
            childImageSharp {
              fluid(maxWidth: 320) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imagePosition
          place {
            name
          }
          _tags
          startPrice
          free
          status
          ticketUrl
          password
          passwordLimitDate
          passwordPrompt
          adultsOnly
        }
      }
    }
  }
`
